import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"

import {
  BulletListWithIcon,
  CheckatradeInfo,
  Faq,
  Layout,
  Seo,
  HeaderBlob,
} from "../components/Shared"
import { GoogleReviews, ThermalImaging } from "../components/Sections"

const SubService = ({ data, location }) => {
  const [visibleForm, setVisibleForm] = React.useState("")
  const subService = data.subService
  const service = data.service

  React.useEffect(() => {
    if (data.subService.forms && data.subService.forms.length > 0) {
      setVisibleForm(data.subService.forms[0].contentful_id)
    }
    return () => {
      setVisibleForm("")
    }
  }, [])
  return (
    <Layout>
      <Seo
        url={location.href}
        title={subService?.seo?.title}
        description={subService?.seo?.metaDescription?.metaDescription}
        image={subService?.seo?.image?.file?.url}
      />
      <HeaderBlob
        image={subService.photo.fluid}
        imageDescription={subService.photo.title}
      >
        <h1 className="heading-extra-large">{subService.headerTitle}</h1>
        <BulletListWithIcon bullets={subService.headerBullets} />
        <CheckatradeInfo />
      </HeaderBlob>
      <a href="tel: 0191 406 0888" className="service-contact">
        <div className="wrapper-width">
          <span className="service-contact-heading">Call us now</span>
          <span className="service-contact-number">0191 406 0888</span>
        </div>
      </a>
      <GoogleReviews />
      {subService?.localServices && subService?.localServices?.length > 0 && (
        <section className="wrapper-width wrapper-height service-offerings">
          <h2 className="heading-large">What we do</h2>
          <p className="service-offerings-introduction">Local services</p>
          <section className="grid-col-3">
            {subService?.localServices.map((localService) => (
              <Link
                to={`/services/${service.slug}/${subService.slug}/${localService.slug}`}
                className="service-offering"
                key={localService.contentful_id}
              >
                <Img
                  className="service-offering-photo"
                  fluid={localService.photo.fluid}
                  alt={localService.photo.title}
                />
                <div className="service-offering-body">
                  <h3 className="heading-small">{localService.name}</h3>
                  <BulletListWithIcon bullets={localService.headerBullets} />
                  <span className="link-with-icon illustrated-option-link-icon service-offering-body-button">
                    <span className="icon-arrow-right" />
                    <span className="link-with-icon-text">Find out more</span>
                  </span>
                </div>
              </Link>
            ))}
            <div className="service-request-callback">
              <Link to="/contact-us" className="button-primary">
                Contact us
              </Link>
            </div>
          </section>
        </section>
      )}
      {subService.forms && subService.forms.length > 0 && (
        <section className="wrapper-width wrapper-height">
          <h2 className="heading-large sub-service-forms-heading">
            Book today
          </h2>
          <div className="sub-service-forms">
            {subService.forms.length > 1 && (
              <header className="sub-service-forms-header">
                {subService.forms.map((form) => (
                  <button
                    key={form.contentful_id}
                    className={`sub-service-forms-header-button 
                    ${
                      form.contentful_id === visibleForm
                        ? "button-primary"
                        : "button-secondary"
                    }
                  `}
                    onClick={() => setVisibleForm(form.contentful_id)}
                  >
                    {form.name}
                  </button>
                ))}
              </header>
            )}
            <div className="sub-service-forms-body">
              {subService.forms.map((form) => (
                <div
                  key={form.contentful_id}
                  className={`sub-service-forms-body-form ${
                    visibleForm === form.contentful_id
                      ? " sub-service-forms-body-form-active"
                      : ""
                  }`}
                  dangerouslySetInnerHTML={{ __html: form.iframe.iframe }}
                />
              ))}
            </div>
          </div>
        </section>
      )}
      {subService.faqs && subService.faqs.length > 0 && (
        <section className="wrapper-width wrapper-height faq-section service-faqs">
          <h2 className="heading-large">Frequently asked questions</h2>
          <div className="faqs">
            {subService.faqs.map((faq) => (
              <Faq key={faq.id} question={faq.question}>
                {faq.answer.answer}
              </Faq>
            ))}
          </div>
        </section>
      )}
      <ThermalImaging />
    </Layout>
  )
}

export default SubService

export const pageQuery = graphql`
  query getSubServicePageDetails($id: String!, $serviceId: String!) {
    subService: contentfulServicePagesSubServicePages(
      contentful_id: { eq: $id }
    ) {
      id
      headerBullets
      headerTitle
      name
      photo {
        fluid(maxWidth: 1600) {
          ...GatsbyContentfulFluid
        }
        title
      }
      slug
      faqs {
        answer {
          answer
        }
        question
        id
      }
      forms {
        iframe {
          iframe
        }
        name
        contentful_id
      }
      localServices {
        photo {
          title
          fluid {
            ...GatsbyContentfulFluid
          }
        }
        name
        headerBullets
        contentful_id
        slug
      }
      seo {
        title
        metaDescription {
          metaDescription
        }
        image {
          file {
            url
          }
        }
      }
    }
    service: contentfulServicePages(contentful_id: { eq: $serviceId }) {
      slug
      contentful_id
    }
  }
`
